import styles from './style.module.scss';
import { useTranslation, Trans } from 'react-i18next';
import { UIButton, UIModal, UIModalProps, UITypography } from 'shared/ui';
import BobeAdsError from 'assets/images/bobe_ads_error.webp';
import BronzeCoinIcon from 'assets/images/bronze-coin.png';
import { skipWaitingOnce } from 'shared/api';
import { useState } from 'react';
import { useGameStore, useGlobalStore } from 'shared/store';
import { useInfoModal } from 'shared/hooks';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'shared/constants';
import EmojiCry from 'assets/images/emoji-cry.webp';

type Variant = 'DEFAULT' | 'NO_ADS';

type TurboBoostModalProps = Omit<UIModalProps, 'children'> & {
  variant?: Variant;
  onComplete?: () => void;
};

export const TurboBoostModal = ({
  visible,
  onClose,
  onComplete = () => {},
  variant = 'DEFAULT',
}: TurboBoostModalProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const infoModal = useInfoModal();
  const tokens = useGlobalStore((state) => state.user.tokens);
  const updateUser = useGlobalStore((state) => state.updateUser);
  const [loading, setLoading] = useState(false);
  const price = useGameStore((state) => state.settings.skip_waiting_once_price);

  const handleComplete = () => {
    if (Number(price) > Number(tokens)) {
      onClose();
      infoModal.open({
        title: (
          <>
            {`${t('common.oops')}!...`}
            <img src={EmojiCry} width={20} height={20} alt="" />
          </>
        ),
        text: t('info_modal.no_bobe_on_balance'),
        buttons: [
          {
            label: t('info_modal.back_to_bakery'),
            onClick: () => {
              navigate(ROUTES.BAKERY);
              infoModal.close();
            },
          },
        ],
      });
      return;
    }

    if (loading) return;
    setLoading(true);
    skipWaitingOnce()
      .then((user) => {
        onComplete();
        onClose();
        updateUser(user);
        return;
      })
      .finally(() => setLoading(false));
  };

  const CurrentModal = variant === 'DEFAULT' ? DefaultTurboBoostModal : NoAdsTurboBoostModal;

  return (
    <CurrentModal
      price={Number(price)}
      visible={visible}
      loading={loading}
      onClick={handleComplete}
      onClose={onClose}
    />
  );
};

type DefaultTurboBoostModalProps = Omit<UIModalProps, 'children'> & {
  loading: boolean;
  price: number;
  onClick: () => void;
};

const DefaultTurboBoostModal = ({
  visible,
  onClose,
  onClick,
  loading,
  price,
}: DefaultTurboBoostModalProps) => {
  const { t } = useTranslation();

  return (
    <UIModal visible={visible} onClose={onClose} detent="content-height">
      <div className={styles.wrapper}>
        <img className={styles.img} src="/rocket.webp" />
        <UITypography className={styles.title} variant="H1">
          <Trans
            defaults="turbo_speed_modal.default_description"
            values={{ turbo_speed: `«${t('common.turbo_speed')}»` }}
            components={{ br: <br></br>, span: <span className={styles._highlight}></span> }}
          />
        </UITypography>

        <UIButton
          fullwidth
          className={styles.btn}
          onClick={onClick}
          loading={loading}
          disabled={loading}
        >
          {t('common.activate_for')} {price} <img src={BronzeCoinIcon} width={24} height={24} />
        </UIButton>
      </div>
    </UIModal>
  );
};

type NoAdsTurboBoostModalProps = Omit<UIModalProps, 'children'> & {
  loading: boolean;
  price: number;
  onClick: () => void;
};

const NoAdsTurboBoostModal = ({
  visible,
  onClose,
  onClick,
  loading,
  price,
}: NoAdsTurboBoostModalProps) => {
  const { t } = useTranslation();

  return (
    <UIModal visible={visible} onClose={onClose} detent="content-height">
      <div className={styles.wrapper}>
        <img className={styles.bobe_img} src={BobeAdsError} />
        <UITypography
          className={styles.title}
          variant="Body"
          style={{
            fontSize: 20,
            lineHeight: 1.25,
          }}
          fontWeight={'extraBold'}
          component="p"
        >
          <div className={styles._highlight}>{t('common.oops')}!...</div>
          <Trans
            defaults="turbo_speed_modal.no_ads_description"
            values={{ secret_pass: `«${t('turbo_speed_modal.secret_pass')}»` }}
            components={{ br: <br></br>, span: <span className={styles._highlight}></span> }}
          />
        </UITypography>

        <UIButton className={styles.btn} onClick={onClick} loading={loading} disabled={loading}>
          {t('common.activate_for')} {price} <img src={BronzeCoinIcon} width={24} height={24} />
        </UIButton>
      </div>
    </UIModal>
  );
};
