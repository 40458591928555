import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, NavigateFunction } from 'react-router-dom';
import { finishSession, startSession } from 'shared/api';
import { CS_KEYS, ROUTES } from 'shared/constants';
import { useGameStore, useGlobalStore } from 'shared/store';
import { checkEarnedCoins, handleErrorWithAlert, handleErrorStatus } from 'shared/utils';

// import { useCloudStorage } from '@vkruglikov/react-telegram-web-app';

type StopGameParams = {
  limitReached: boolean;
  _currCoins: number;
  failedCoins: number;
  _catchedBees: number;
  _sessionId: string;
};

export const leaveSession = (nav: NavigateFunction, limitReached: boolean, coins: number) => {
  if (limitReached) {
    nav(ROUTES.LIMIT_REACHED, {
      replace: true,
      state: { coins: coins },
    });
  } else {
    nav(ROUTES.CONGRATULATIONS, {
      replace: true,
      state: { coins: coins },
    });
  }
};

export function useGame() {
  // const { setItem } = useCloudStorage();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const donutsCleared = useGameStore((state) => state.donutsCleared);
  const setSessionId = useGameStore((state) => state.setSessionId);
  const updateUser = useGlobalStore((state) => state.updateUser);
  const increaseCurrentLimit = useGlobalStore((state) => state.increaseCurrentLimit);
  const resetGameState = useGameStore((state) => state.resetGameState);

  const startGame = async () => {
    try {
      useGameStore.getState().resetGameState();

      const res = await startSession();

      // Initialize session config
      useGameStore.getState().setSessionConfig({
        total_donuts_count: res.data.total_donuts_count,
        useful_donuts_points: res.data.useful_donuts_points,
        donut_spacing: res.data.donut_spacing,
        session_duration: res.data.session_duration,
        first_phase_ends_at_seconds: res.data.first_phase_ends_at_seconds,
        second_phase_ends_at_seconds: res.data.second_phase_ends_at_seconds,
        v0: res.data.v0,
        v1: res.data.v1,
        v2: res.data.v2,
        v3: res.data.v3,

        user_id: res.data.user_id,
        uuid: res.data.uuid,
        last_start: res.data.last_start,
      });

      // Initialize donut sequence
      useGameStore.getState().initializeDonutSequence();

      setSessionId(res.data.uuid);
      updateUser({ last_start: res.data.last_start });
    } catch (error) {
      console.log(error);
      navigate(ROUTES.MAIN, { replace: true });
      handleErrorWithAlert(error, t('alerts.something_went_wrong'));
    }
  };

  const stopGame = async ({
    _currCoins,
    failedCoins,
    _catchedBees,
    limitReached,
    _sessionId,
  }: StopGameParams) => {
    try {
      if (!_sessionId) return;

      const res = await finishSession(_sessionId, _currCoins, _catchedBees, failedCoins);
      increaseCurrentLimit(_currCoins);
      updateUser({
        coins: res.data.total_coins,
        last_finish: res.data.last_finish,
        next_available_start: res.data.next_available_start,
      });
      leaveSession(navigate, limitReached, _currCoins);
    } catch (error) {
      console.log(error);
      handleErrorStatus(
        error,
        409,
        () => {
          alert(t('alerts.you_already_started_session'));
          navigate(ROUTES.MAIN, { replace: true });
        },
        () => {
          alert(t('alerts.something_went_wrong'));
          navigate(ROUTES.MAIN, { replace: true });
        },
      );
    } finally {
      resetGameState();
    }
  };

  useEffect(() => {
    startGame();
  }, []);

  useEffect(() => {
    const handleFinishGame = async () => {
      const sessionId = useGameStore.getState().sessionId;
      if (!donutsCleared || !sessionId) return;

      const currCoins = useGameStore.getState().currCoins;
      const { current_limit, max_daily_limit } = useGlobalStore.getState().user;

      const isLimitReached = currCoins + current_limit >= max_daily_limit;

      const earnedCoins = checkEarnedCoins({
        dailyLimit: max_daily_limit,
        currCoins: currCoins,
        currentLimit: current_limit,
        limitReached: isLimitReached,
      });

      await stopGame({
        _currCoins: earnedCoins,
        failedCoins: useGameStore.getState().failedDonuts,
        _catchedBees: useGameStore.getState().catchedBees,
        limitReached: isLimitReached,
        _sessionId: sessionId,
      });
    };

    handleFinishGame();
  }, [donutsCleared]);

  return {
    startGame,
    stopGame,
  };
}
