import { useWebApp } from '@vkruglikov/react-telegram-web-app';
import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'shared/constants';
import { useGlobalStore } from 'shared/store';
import { useTranslation } from 'react-i18next';

const ROUTES_WITH_FULLSCREEN = [ROUTES.SESSION];

export const useAppFullscreen = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const WebApp = useWebApp();

  const { setFullscreen, setContentSafeArea } = useGlobalStore((state) => state);
  const { requestFullscreen, exitFullscreen, isFullscreen, lockOrientation } =
    window.Telegram.WebApp;

  setFullscreen(isFullscreen ?? false);

  WebApp.onEvent('fullscreenChanged', () => {
    setFullscreen(window.Telegram.WebApp.isFullscreen ?? false);
  });

  WebApp.onEvent('contentSafeAreaChanged', () => {
    setContentSafeArea(window.Telegram.WebApp.contentSafeAreaInset!);
  });

  WebApp.onEvent('fullscreenFailed', () => {
    setFullscreen(window.Telegram.WebApp.isFullscreen ?? false);
  });

  // orientation
  useLayoutEffect(() => {
    if (!window.Telegram?.WebApp || !window.Telegram.WebApp.isVersionAtLeast('8.0')) {
      return;
    }

    let orientationCheckInterval: NodeJS.Timeout;
    let popupShown = false;
    let isLocked = false;

    const showOrientationPopup = () => {
      if (isLocked) return;

      popupShown = true;
      WebApp.showPopup(
        {
          title: t('orientation.title'),
          message: t('orientation.message'),
          buttons: [{ type: 'ok' }],
        },
        () => {
          popupShown = false;
          if (!isLocked && window.innerWidth > window.innerHeight) {
            showOrientationPopup();
          }
        },
      );
    };

    const isLandscape = window.innerWidth > window.innerHeight;

    if (!isLandscape && lockOrientation) {
      lockOrientation();
      isLocked = true;
    } else if (isLandscape) {
      showOrientationPopup();

      orientationCheckInterval = setInterval(() => {
        const currentIsLandscape = window.innerWidth > window.innerHeight;

        if (!currentIsLandscape && popupShown) {
          if (lockOrientation) {
            lockOrientation();
            isLocked = true;
            popupShown = false;
            clearInterval(orientationCheckInterval);
          }
        }
      }, 250);
    }

    return () => {
      if (orientationCheckInterval) {
        clearInterval(orientationCheckInterval);
      }
    };
  }, []);

  // fullscreen
  useLayoutEffect(() => {
    if (
      !window.Telegram?.WebApp ||
      !window.Telegram.WebApp.isVersionAtLeast('8.0') ||
      !['android', 'ios'].includes(window.Telegram.WebApp.platform)
    ) {
      return;
    }

    if (ROUTES_WITH_FULLSCREEN.includes(location.pathname) && requestFullscreen) {
      requestFullscreen();
      return;
    }

    if (exitFullscreen) {
      exitFullscreen();
    }
  }, [location.pathname]);
};
