import styles from 'modules/game-session-module/style.module.scss';
import { GameContainer } from 'modules/game-session-module/game-container';
import { useGame } from 'modules/game-session-module/hooks/use-game';

import { GamePanel } from 'components';
import { useVisibilityChange } from 'shared/hooks';
import { useGameStore } from 'shared/store';

export const GameSessionScreen = () => {
  useVisibilityChange();
  const { isLoading } = useGameStore((state) => ({
    isLoading: !state.sessionConfig,
    sessionConfig: state.sessionConfig,
  }));

  useGame();

  if (isLoading) {
    return <>{/* <p>loading...loading...loading...loading...loading...</p> */}</>;
  }

  return (
    <div className={styles.root}>
      <GamePanel />
      <GameContainer />
    </div>
  );
};
