import { UIInfoModal } from 'components/modals/info-modal/info-modal';

type InfoModalProvider = {
  children: React.ReactNode;
};

export const InfoModalProvider = ({ children }: InfoModalProvider) => {
  return (
    <>
      {children}
      <UIInfoModal />
    </>
  );
};
