import { MutableRefObject } from 'react';

export const clearEntityQueue = <Entity extends { id: string }>(
  prev: Entity[],
  deleteQueue: MutableRefObject<string[]>,
) => {
  return prev.filter((entity) => {
    if (deleteQueue.current.includes(entity.id)) {
      deleteQueue.current = deleteQueue.current.filter((id) => id !== entity.id);
      return false;
    }
    return true;
  });
};
