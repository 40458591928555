import styles from './style.module.scss';
import { UIButton, UIDivider, UITypography } from 'shared/ui';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UserTickIcon } from 'assets/icons/user-tick.svg';
import { getRefs, useFetchRefs } from 'shared/api';
import { LEFT_SWIPE_CHILD_VARIANTS, REFS_LIST_LIMIT } from 'shared/constants';
import { UserWidget } from 'components';
import { Spinner } from 'components/loading/spinner';
import { m } from 'framer-motion';
import TabsReferrals from './tabs';

export const ReferralsList = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const offset = useRef(REFS_LIST_LIMIT);
  const { loading: initLoading, refsData, level, onChangeTab, setRefsData } = useFetchRefs(offset);

  const loadMore = async () => {
    try {
      setLoading(true);

      offset.current = offset.current + REFS_LIST_LIMIT;

      const res = await getRefs(REFS_LIST_LIMIT, offset.current, level);
      setRefsData((prev) => ({ count: res.data.count, data: [...prev.data, ...res.data.data] }));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  if (initLoading) {
    return (
      <div className={styles.root}>
        <div className={styles.initialLoading}>
          <Spinner size={30} />
        </div>
      </div>
    );
  }

  return (
    <m.div
      className={styles.root}
      variants={LEFT_SWIPE_CHILD_VARIANTS}
      initial="hidden"
      animate="visible"
    >
      <UITypography className={styles.invitedFriends} iconLeft={<UserTickIcon />}>
        {`${t('friends.invited_friends')} (${refsData.count})`}
      </UITypography>

      <TabsReferrals level={level} onChange={onChangeTab} />

      {refsData.count > 0 && (
        <>
          <div className={styles.listAndButtonContainer}>
            {refsData.data.map((referral, index) => (
              <div className={styles.list} key={referral.uuid}>
                <div style={{ padding: '12px 0' }}>
                  <UserWidget
                    username={referral.username || referral.first_name}
                    isReferral
                    isApproved={referral.is_approved}
                    percengage={referral.percentage_award}
                    tgUsername={referral.username}
                  />
                </div>

                {index !== refsData.data.length - 1 && <UIDivider />}
              </div>
            ))}

            {refsData.data.length < refsData.count && (
              <UIButton
                variant="text"
                disabled={loading}
                onClick={loadMore}
                className={styles.loadMoreButton}
                loading={loading}
              >
                {t('common.load_more')}
              </UIButton>
            )}
          </div>
        </>
      )}
    </m.div>
  );
};
