import styles from './style.module.scss';
import { Fly, FreezeOverlay, Donut } from 'components';
import { useDonuts } from './hooks/use-donuts';
import { useFlies } from './hooks/use-flies';
import { useCallback, useEffect, useRef, useState, memo } from 'react';
import { useGameStore } from 'shared/store';
import { audioManager } from 'shared/service/audio';

// const FPSMonitor = () => {
//   const [fps, setFps] = useState(0);

//   useEffect(() => {
//     let frameCount = 0;
//     let lastTime = performance.now();

//     const measureFPS = () => {
//       const currentTime = performance.now();
//       frameCount++;

//       if (currentTime - lastTime > 1000) {
//         setFps(Math.round((frameCount * 1000) / (currentTime - lastTime)));
//         frameCount = 0;
//         lastTime = currentTime;
//       }

//       requestAnimationFrame(measureFPS);
//     };

//     requestAnimationFrame(measureFPS);
//   }, []);

//   return (
//     <div
//       style={{
//         position: 'fixed',
//         top: 10,
//         right: 10,
//         background: 'rgba(1,0,0,0.8)',
//         color: 'white',
//         padding: '15px 15px',
//         borderRadius: '5px',
//         zIndex: 9999,
//       }}
//     >
//       FPS: {fps}
//     </div>
//   );
// };

const DonutContainer = () => {
  const { donuts, handleDonutClick, onDonAnimationComplete } = useDonuts();
  const containerRef = useRef<HTMLDivElement>(null);
  // console.log('Rendering DonutContainer, donuts count:', donuts.length);

  useEffect(() => {
    const handleDonAnimationEnd = (e: any) => {
      if (e.target.id.startsWith('don-id')) {
        onDonAnimationComplete(e.target.id);
      }
    };
    containerRef.current?.addEventListener('animationend', handleDonAnimationEnd);
    return () => {
      containerRef.current?.removeEventListener('animationend', handleDonAnimationEnd);
    };
  }, []);

  return (
    <div className={styles['game-container']} ref={containerRef}>
      {donuts.map((donut) => {
        // console.log('Mapping donut:', donut.id);
        return (
          <Donut
            {...donut}
            key={donut.id}
            onClick={handleDonutClick}
            onAnimationComplete={onDonAnimationComplete}
          />
        );
      })}
    </div>
  );
};

const FliesContainer = () => {
  const { flies, freezeCooldown, onFlyClick, onFlyAnimationComplete } = useFlies();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleFlyAnimationEnd = (e: any) => {
      onFlyAnimationComplete(e.target.id);
    };

    const current = containerRef.current;
    current?.addEventListener('animationend', handleFlyAnimationEnd);

    return () => {
      current?.removeEventListener('animationend', handleFlyAnimationEnd);
    };
  }, [onFlyAnimationComplete]);

  return (
    <div className={styles['game-container']} ref={containerRef}>
      {flies.map((fly) => (
        <Fly
          {...fly}
          key={fly.id}
          onClick={onFlyClick}
          onAnimationComplete={onFlyAnimationComplete}
        />
      ))}

      <FreezeOverlay freezeCooldown={freezeCooldown} />
    </div>
  );
};

export const GameContainer = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const setContainerHeight = useGameStore((state) => state.setContainerHeight);

  useEffect(() => {
    if (!containerRef.current) return;

    const updateContainer = () => {
      const height = containerRef.current?.clientHeight;
      if (height) {
        setContainerHeight(height);
        const fallDistance = window.innerHeight + 140;
        document.documentElement.style.setProperty('--donut-fall-distance', `${fallDistance}px`);
      }
    };

    updateContainer();
    window.addEventListener('resize', updateContainer);
    return () => window.removeEventListener('resize', updateContainer);
  }, [setContainerHeight]);

  // useEffect(() => {
  //   audioManager.initialize();

  //   const handleVisibilityChange = () => {
  //     audioManager.initialize();
  //   };

  //   document.addEventListener('visibilitychange', handleVisibilityChange);
  //   return () => {
  //     document.removeEventListener('visibilitychange', handleVisibilityChange);
  //   };
  // }, []);

  return (
    <div className={styles.container} ref={containerRef}>
      {/* <FPSMonitor /> */}
      <DonutContainer />
      <FliesContainer />
    </div>
  );
};
