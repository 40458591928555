import { useCallback, useEffect, useRef, useState } from 'react';
import { DONUT_TYPES, DONUT_SIZES, DEFAULT_FINGER_SLIDER_CONFIG } from 'shared/constants';
import { randomNumberBetween } from 'shared/utils';
import { useGameStore, useGlobalStore } from 'shared/store';
import { DonutEntity, DonutSize } from 'shared/types';
import { v4 } from 'uuid';

export const useDonuts = () => {
  const [donuts, setDonuts] = useState<DonutEntity[]>([]);
  const currentDonutIndexRef = useRef(0);

  const { user } = useGlobalStore((state) => ({
    user: state.user,
  }));

  const {
    addCurrCoins,
    gameFinished,
    increaseFailedDonuts,
    setDonutsCleared,
    setGameFinished,
    sessionConfig,
    containerHeight,
    donutSequence,
  } = useGameStore((state) => ({
    sessionConfig: state.sessionConfig,
    containerHeight: state.containerHeight,
    donutSequence: state.donutSequence,
    gameFinished: state.gameFinished,
    setDonutsCleared: state.setDonutsCleared,
    setGameFinished: state.setGameFinished,
    increaseFailedDonuts: state.increaseFailedDonuts,
    addCurrCoins: state.addCurrCoins,
  }));

  const userFingerSize = parseInt(user.finger_size || '') || DEFAULT_FINGER_SLIDER_CONFIG.min;
  const currentCoinsCache = useRef(0);

  // Calculate current speed based on game phase
  const getCurrentSpeed = useCallback(() => {
    if (!sessionConfig) return 0;
    const { elapsedTime } = useGameStore.getState();

    // Phase 1: v0 to v1
    if (elapsedTime <= sessionConfig.first_phase_ends_at_seconds) {
      const phaseProgress = elapsedTime / sessionConfig.first_phase_ends_at_seconds;
      return sessionConfig.v0 + (sessionConfig.v1 - sessionConfig.v0) * phaseProgress;
    }

    // Phase 2: v1 to v2
    if (elapsedTime <= sessionConfig.second_phase_ends_at_seconds) {
      const phaseProgress =
        (elapsedTime - sessionConfig.first_phase_ends_at_seconds) /
        (sessionConfig.second_phase_ends_at_seconds - sessionConfig.first_phase_ends_at_seconds);
      return sessionConfig.v1 + (sessionConfig.v2 - sessionConfig.v1) * phaseProgress;
    }

    // Phase 3: v2 to v3
    const phaseProgress =
      (elapsedTime - sessionConfig.second_phase_ends_at_seconds) /
      (sessionConfig.session_duration - sessionConfig.second_phase_ends_at_seconds);
    return sessionConfig.v2 + (sessionConfig.v3 - sessionConfig.v2) * phaseProgress;
  }, [sessionConfig]);

  // const fallenDonutsRef = useRef<Set<string>>(new Set());
  const onDonAnimationComplete = useCallback((id: string) => {
    // fallenDonutsRef.current.add(id);
    setDonuts((prev) => prev.filter((donut) => donut.id !== id));
  }, []);

  const handleDonutClick = useCallback(
    (donut: DonutEntity) => {
      if (userFingerSize === donut.size) {
        currentCoinsCache.current += donut.value;

        if (currentCoinsCache.current + user.current_limit >= user.max_daily_limit) {
          const diff = currentCoinsCache.current + user.current_limit - user.max_daily_limit;

          const finalValue = donut.value - diff > 0 ? donut.value - diff : 0;

          addCurrCoins(finalValue);
          setGameFinished(true);
          return { isCorrect: true, value: finalValue };
        }

        addCurrCoins(donut.value);
        return { isCorrect: true, value: donut.value };
      }

      increaseFailedDonuts(1);
      return { isCorrect: false, value: 0 };
    },
    [userFingerSize],
  );

  // Create a donut with the next points value from our sequence
  const createDonut = useCallback(
    // (currentSpeed: number) => {
    (currentSpeed: number, startDelay: number = 0) => {
      if (!sessionConfig || currentDonutIndexRef.current >= donutSequence.length) return null;

      const points = donutSequence[currentDonutIndexRef.current];
      const availableSizes = DONUT_SIZES.filter((size) => size !== userFingerSize);

      const size =
        points > 0
          ? (userFingerSize as DonutSize)
          : availableSizes[randomNumberBetween(0, availableSizes.length - 1)];

      // Increment the donut index after getting the points
      currentDonutIndexRef.current += 1;

      return {
        id: `don-id${v4()}`,
        type: DONUT_TYPES[randomNumberBetween(0, DONUT_TYPES.length - 1)],
        positionX: randomNumberBetween(0, window.innerWidth - 100),
        size: size,
        value: points,
        fallDuration: 1 / currentSpeed,
        startDelay,
      };
    },
    [sessionConfig, donutSequence, userFingerSize],
  );

  // The spawn loop that creates new donuts
  useEffect(() => {
    if (!sessionConfig || !containerHeight || gameFinished) {
      currentDonutIndexRef.current = 0;
      // Clear existing donuts when game finishes
      if (gameFinished) {
        setDonuts([]);
        setDonutsCleared(true);
      }
      return;
    }

    let timeoutId: NodeJS.Timeout;

    // const BATCH_SIZE = 10;
    // const spawnDonutBatch = () => {
    //   if (gameFinished) return;

    //   const currentSpeed = getCurrentSpeed();
    //   const newDonuts: DonutEntity[] = [];

    //   const spacingTime =
    //     (sessionConfig.donut_spacing * containerHeight) / (currentSpeed * containerHeight);

    //   for (let i = 0; i < BATCH_SIZE; i++) {
    //     const donut = createDonut(currentSpeed, i * spacingTime);
    //     if (donut) {
    //       newDonuts.push(donut);
    //     }
    //   }

    //   if (newDonuts.length > 0) {
    //     setDonuts((prevDonuts) => {
    //       // Удаляем упавшие и добавляем новые за одну операцию
    //       const filteredDonuts =
    //         fallenDonutsRef.current.size > 0
    //           ? prevDonuts.filter((donut) => !fallenDonutsRef.current.has(donut.id))
    //           : prevDonuts;

    //       fallenDonutsRef.current.clear();
    //       return [...filteredDonuts, ...newDonuts];
    //     });

    //     // setDonuts((prev) => [...prev, ...newDonuts]);

    //     const totalBatchTime =
    //       (BATCH_SIZE * (sessionConfig.donut_spacing * containerHeight)) /
    //       (currentSpeed * containerHeight);
    //     timeoutId = setTimeout(spawnDonutBatch, totalBatchTime * 1000);
    //   }
    // };
    // spawnDonutBatch();

    const spawnDonut = () => {
      if (gameFinished) return;

      const currentSpeed = getCurrentSpeed();
      const newDonut = createDonut(currentSpeed);
      if (newDonut) {
        setDonuts((prev) => [...prev, newDonut]);
        const spacingTime =
          (sessionConfig.donut_spacing * containerHeight) / (currentSpeed * containerHeight);
        timeoutId = setTimeout(spawnDonut, spacingTime * 1000);
      }
    };
    spawnDonut();

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [sessionConfig, containerHeight, gameFinished]);

  return {
    donuts,
    handleDonutClick,
    onDonAnimationComplete,
  };
};
