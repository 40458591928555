import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { ROUTES, GAME_COIN_SHORTCUT, ADS_BLOCK_ID } from 'shared/constants';
import { UITypography, UIButton } from 'shared/ui';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGlobalStore } from 'shared/store';
import { formatLargeNumber } from 'shared/utils';
import { Scale, Swipe } from 'shared/ui/animations';
import { ShowAdsButton } from 'components';

export const CongratulationsModule = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const user = useGlobalStore((state) => state.user);
  const isDayCooldown = user.current_limit >= user.max_daily_limit;
  const botInfoLang = useGlobalStore((state) => state.botInfo.lang);
  const earnedCoins = location.state?.coins;

  const goToMain = () => {
    navigate(ROUTES.MAIN, { replace: true });
  };

  return (
    <div className={styles.root}>
      <Scale>
        <img src={'/donuts/three-donuts.png'} alt="" width={264} height={170} />
      </Scale>

      <Swipe direction="horizontal" from="left">
        <div className={styles.text}>
          <UITypography component="p" variant="H1">
            {t('congratulations_screen.your_result')}
          </UITypography>
          <UITypography
            variant="H1"
            component="p"
            color="yellow"
            className={styles.donutsCount}
          >{`+${formatLargeNumber(earnedCoins || 0)} ${GAME_COIN_SHORTCUT}`}</UITypography>
        </div>
      </Swipe>

      {!isDayCooldown && (
        <ShowAdsButton
          rewardType="start_session_instantly"
          blockId={ADS_BLOCK_ID[botInfoLang]}
          textRight={t('congratulations_screen.instant_start_session')}
        />
      )}

      <Swipe direction="horizontal" from="right" style={{ width: '100%' }}>
        <UIButton fullwidth onClick={goToMain}>
          {t('congratulations_screen.back_to_main')}
        </UIButton>
      </Swipe>
    </div>
  );
};
