import { GAME_COIN_SHORTCUT } from 'shared/constants';
import styles from './style.module.scss';
import { UIButton, UIModal, UIModalProps, UITypography } from 'shared/ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type ConfirmConvertModalProps = UIModalProps & {
  convertAmount: string;
  onConfirm: () => Promise<void>;
};

export const ConfirmConvertModal = ({
  convertAmount,
  onConfirm,
  ...other
}: ConfirmConvertModalProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    setLoading(true);
    await onConfirm();
    setLoading(false);
  };

  return (
    <UIModal detent="content-height" {...other}>
      <div className={styles.root}>
        <img
          src={'/donuts/three-donuts.png'}
          alt=""
          width={264}
          height={170}
          style={{ display: 'block' }}
        />
        <UITypography variant="H1">
          {t('bakery.shure_to_convert')}
          <UITypography
            variant="H1"
            color="yellow"
            preline
          >{`\n ${convertAmount} ${GAME_COIN_SHORTCUT}`}</UITypography>
          {'?'}
        </UITypography>

        <UIButton fullwidth onClick={handleConfirm} loading={loading}>
          {t('common.confirm')}
        </UIButton>
      </div>
    </UIModal>
  );
};
