import styles from './style.module.scss';
import { ReactComponent as MathIcon } from 'assets/icons/math.svg';
import { UIButton, UINumberInput, UITypography } from 'shared/ui';
import { useTranslation } from 'react-i18next';
import { TOKEN_SHORTCUT, GAME_COIN_SHORTCUT } from 'shared/constants';
import { useRef, useState } from 'react';
import { formatLargeNumber } from 'shared/utils';
import { useGameStore, useGlobalStore } from 'shared/store';
import bronzeCoin from 'assets/images/bronze-coin.png';
import { bakeryConvert } from 'shared/api';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down-2.svg';
import { ConfirmConvertModal, SucessConvertModal } from 'components';

export const Conversation = () => {
  const { t } = useTranslation();

  const user = useGlobalStore((state) => state.user);
  const settings = useGameStore((state) => state.settings);
  const updateUser = useGlobalStore((state) => state.updateUser);
  const minConvertAmount = useGameStore((state) => state.settings.min_conversion_amount);

  const [inputValue, setInputValue] = useState(`0`);
  const inputRef = useRef<HTMLInputElement>(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [successConvertModalOpen, setSuccessConvertModalOpen] = useState(false);

  const convertedValue = formatLargeNumber(
    +inputValue.replaceAll(' ', '') / settings.conversion_rate,
    8,
  );

  const handleConvert = async () => {
    const _value = +inputValue.replaceAll(' ', '');

    if (_value < minConvertAmount) {
      alert(
        `${t('alerts.min_amount_to_convert')} ${formatLargeNumber(minConvertAmount)} ${GAME_COIN_SHORTCUT}`,
      );
      return;
    }
    if (_value > Number(user.coins)) {
      alert(`${t('alerts.not_enough_balance')}`);
      return;
    }

    if (!/^(?!.*\.\.)\d+(\.\d{0,4})?$/.test(_value.toString())) {
      alert('alerts.incorrect_format');
      return;
    }

    setConfirmModalOpen(true);
  };

  const onConfirmConvert = async () => {
    try {
      const _value = +inputValue.replaceAll(' ', '');
      const newUser = await bakeryConvert(_value);
      updateUser({ coins: newUser.coins, tokens: newUser.tokens });
      setConfirmModalOpen(false);
      setSuccessConvertModalOpen(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (value: string) => {
    setInputValue(value);
    if (inputRef.current) {
      inputRef.current.value = value;
    }
  };

  const onInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setInputValue('0');
    }
  };

  const handleInputFocus = () => {
    if (!inputRef.current) return;
    inputRef.current.focus();

    if (inputRef.current.value === '0') {
      setInputValue('');
    }
    const length = inputRef.current.value.length;
    inputRef.current.setSelectionRange(length, length);
  };

  const onCloseSuccessConvertModal = () => {
    setSuccessConvertModalOpen(false);
    setInputValue('0');
  };

  return (
    <div className={styles.root}>
      <UITypography iconLeft={<MathIcon />}>{t('bakery.conversion')}</UITypography>
      <div className={styles.fieldsContainer}>
        <div className={styles.field} onClick={handleInputFocus}>
          <img src="/donuts/don-coin.webp" alt="" width={50} height={50} />

          <div className={styles.inputContainer}>
            <UINumberInput
              ref={inputRef}
              value={inputValue}
              onChange={handleInputChange}
              onBlur={onInputBlur}
              onTouchStart={handleInputFocus}
              className={styles.input}
            />
            <UITypography fontWeight="bold">{GAME_COIN_SHORTCUT}</UITypography>
          </div>
        </div>

        <div className={styles.convertRateRoot}>
          <div className={styles.convertRateContainer}>
            <ArrowDown />
            <UITypography>{`${formatLargeNumber(settings.conversion_rate)} ${GAME_COIN_SHORTCUT} = 1 ${TOKEN_SHORTCUT}`}</UITypography>
          </div>
        </div>

        <div className={styles.field}>
          <img src={bronzeCoin} alt="" width={50} height={50} />

          <div className={styles.inputContainer}>
            <UITypography fontWeight="bold">{`${convertedValue} ${TOKEN_SHORTCUT}`}</UITypography>
          </div>
        </div>
      </div>

      <UIButton fullwidth onClick={handleConvert}>
        {t('bakery.convert')}
      </UIButton>

      <ConfirmConvertModal
        visible={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        convertAmount={inputValue}
        onConfirm={onConfirmConvert}
      />

      <SucessConvertModal
        visible={successConvertModalOpen}
        onClose={onCloseSuccessConvertModal}
        resultAmount={convertedValue}
      />
    </div>
  );
};
