class AudioManager {
  private soundPools: { [key: string]: HTMLAudioElement[] } = {};
  private poolIndexes: { [key: string]: number } = {};
  private webAudioContext: AudioContext | null = null;
  private initialized = false;
  private readonly POOL_SIZE = 3;

  constructor() {
    this.soundPools = {
      coin: this.createAudioPool('/coins.mp3'),
      bee: this.createAudioPool('/bees.mp3'),
    };

    Object.keys(this.soundPools).forEach((name) => {
      this.poolIndexes[name] = 0;
    });

    // Object.values(this.soundPools)
    //   .flat()
    //   .forEach((sound) => {
    //     sound.load();
    //   });
  }

  private createAudioPool(src: string): HTMLAudioElement[] {
    return Array.from({ length: this.POOL_SIZE }, () => {
      const audio = new Audio(src);
      // audio.volume = 0;
      audio.preload = 'auto';

      audio.addEventListener('ended', () => {
        audio.currentTime = 0;
      });

      return audio;
    });
  }

  private async ensureAudioContext() {
    // if (!this.webAudioContext) {
    //   // alert('no webAudioContext!');
    //   this.webAudioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
    // }

    // if (this.webAudioContext.state === 'suspended') {
    //   // alert('suspended webAudioContext!');
    //   await this.webAudioContext.resume();
    // }
    if (!this.webAudioContext || this.webAudioContext.state === 'closed') {
      // console.log('Creating new AudioContext (old was null or closed)');
      this.webAudioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
    }

    if (this.webAudioContext.state !== 'running') {
      // console.log(`AudioContext in ${this.webAudioContext.state} state, attempting to resume`);
      try {
        await this.webAudioContext.resume();
        // console.log('AudioContext resumed successfully');
      } catch (error) {
        // console.error('Failed to resume AudioContext:', error);
        // Пробуем пересоздать контекст
        this.webAudioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
        await this.webAudioContext.resume();
      }
    }
  }

  async initialize() {
    await this.ensureAudioContext();

    if (this.initialized) return;

    try {
      for (const pool of Object.values(this.soundPools)) {
        for (const sound of pool) {
          try {
            sound.volume = 0;
            sound.play();
            sound.pause();
          } catch (error) {
            // console.error('Failed to initialize sound:', error);
          }
        }
      }

      for (const pool of Object.values(this.soundPools)) {
        for (const sound of pool) {
          sound.volume = 1;
          sound.currentTime = 0;
        }
      }

      this.initialized = true;
      // alert('inited!');
    } catch (error) {
      // alert('Could not initialize sound');
      // alert(error);
      // console.error('Audio initialization failed:', error);
      this.initialized = false;
    }
  }

  async play(soundName: 'coin' | 'bee') {
    await this.ensureAudioContext();

    if (!this.initialized) {
      // alert('inagemae init err!');
      await this.initialize();
    }

    const pool = this.soundPools[soundName];
    if (!pool) return;

    // Use round-robin approach instead of searching
    const index = this.poolIndexes[soundName];
    const sound = pool[index];

    // Update index for next play
    this.poolIndexes[soundName] = (index + 1) % this.POOL_SIZE;

    // Play immediately without checking state
    sound.currentTime = 0;
    // alert('play' + soundName + !!pool + index + !!sound);
    await sound.play().catch((error) => {
      // console.error('Playback failed:', error);
      // alert('error' + soundName + !!pool + index + !!sound);
    });
  }
}

export const audioManager = new AudioManager();
