import styles from './style.module.scss';
import { UIButton, UITypography } from 'shared/ui';
import { TaskEntity } from 'shared/types';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down-icon.svg';
import { TASK_AWARD_TYPE, TASK_STATUS } from 'shared/constants';
import { useTranslation } from 'react-i18next';
import { formatLargeNumber } from 'shared/utils';
import { memo, useMemo } from 'react';

type Props = {
  task: TaskEntity;
  onClick: (task: TaskEntity) => void;
};

const isEqual = (prev: Props, next: Props) =>
  prev.task.id === next.task.id && prev.task.status === next.task.status;

export const Task = memo(({ task, onClick }: Props) => {
  const { t } = useTranslation();
  const { award_kind, award_value, title, status } = task;

  const handleClick = () => {
    onClick(task);
  };

  const rightControl = useMemo(() => {
    if (status === TASK_STATUS.TO_DO) {
      return (
        <div className={styles.arrowContainer}>
          <ArrowDownIcon className={styles.arrow} />
        </div>
      );
    }
    if (status === TASK_STATUS.PENDING) {
      return (
        <UIButton size="small2" fontVariant="Button2" className={styles.button}>
          {t('common.pending')}
        </UIButton>
      );
    }
    if (status === TASK_STATUS.DONE) {
      return (
        <UIButton size="small2" fontVariant="Button2" disabled>
          {t('common.completed')}
        </UIButton>
      );
    }

    return null;
  }, [status, t]);

  return (
    <div className={styles.root} onClick={handleClick}>
      <div className={styles.infoContainer}>
        <UITypography variant="H2" component="p">
          {title}
        </UITypography>

        <UITypography
          iconLeft={
            <img
              src={'/donuts/blue-donut-30.webp'}
              alt=""
              width={24}
              height={24}
              style={{ display: 'block' }}
            />
          }
        >
          {`+${formatLargeNumber(award_value)} ${TASK_AWARD_TYPE[award_kind]?.toUpperCase() || ''}`}
        </UITypography>
      </div>

      <div style={{ flexShrink: 0 }}>{rightControl}</div>
    </div>
  );
}, isEqual);
