import styles from './style.module.scss';
import { UIButton, UIModal, UIModalProps, UITypography } from 'shared/ui';
import { TickCircle } from 'assets/icons/tick-circle';
import variables from 'shared/ui/theme/variables.scss';
import { useState } from 'react';
import { useGlobalStore } from 'shared/store';

type NextLeagueCondition = {
  title: string;
  completed: boolean;
  id: string;
};

type NextLeagueConditionsModalProps = UIModalProps & {
  conditions: NextLeagueCondition[];
  title: string;
  img: string;
  buttonText: string;
  onSuccess: () => Promise<void>;
  price: number;
};

export const NextLeagueConditionsModal = ({
  conditions,
  title,
  visible,
  img,
  buttonText,
  price,
  onClose,
  onSuccess,
}: NextLeagueConditionsModalProps) => {
  const user = useGlobalStore((state) => state.user);
  const [loading, setLoading] = useState(false);
  const buttonDisabled =
    conditions.some((condition) => !condition.completed) || Number(user.coins) < price;

  const onButtonClick = async () => {
    setLoading(true);
    await onSuccess();
    setLoading(false);
  };

  return (
    <UIModal onClose={onClose} visible={visible} detent="content-height">
      <div className={styles.root}>
        <img src={img} width={80} height={80} style={{ display: 'block' }} />
        <UITypography variant="H1" className={styles.title}>
          {title}
        </UITypography>
        <div className={styles.conditionsRoot}>
          {conditions.map((condition, index) => (
            <UITypography
              className={styles.requirementItem}
              style={{
                fontSize: 14,
                wordBreak: 'break-word',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                textAlign: 'left',
              }}
              iconLeft={
                <TickCircle
                  bgColor={condition.completed ? variables.colorLightGreen : variables.colorWhite}
                  opacity={condition.completed ? 1 : 0.4}
                  tickColor={condition.completed ? variables.colorBlack : variables.colorWhite}
                />
              }
              key={index}
              fontWeight="bold"
            >
              {condition.title}
            </UITypography>
          ))}
        </div>

        <UIButton fullwidth loading={loading} disabled={buttonDisabled} onClick={onButtonClick}>
          {buttonText}
        </UIButton>
      </div>
    </UIModal>
  );
};
