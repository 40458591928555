import styles from './style.module.scss';
import { UserSizeDonut } from './user-size-donut';
import { SessionCooldownTimer } from './session-cooldown-timer';
import { Boosters } from './boosters';
import { Limit } from './limit';
import { useVisibilityChange } from 'shared/hooks';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'shared/constants';

export const MainScreenModule = () => {
  const navigate = useNavigate();
  const [isExiting, setIsExiting] = useState(false);
  useVisibilityChange();

  const handleGameStart = () => {
    setIsExiting(true);
    setTimeout(() => {
      navigate(ROUTES.SESSION);
    }, 0);
  };

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={isExiting ? styles.animateFromTop : ''}>
          <Boosters />
        </div>

        <UserSizeDonut onGameStart={handleGameStart} />

        <SessionCooldownTimer onGameStart={handleGameStart} />

        <div className={isExiting ? styles.animateFromBottom : ''}>
          <Limit />
        </div>
      </div>
    </div>
  );
};
