import React, { useState } from 'react';
import { Slider } from '../../components/onboarding';
import { ONBOARDING_STEPS } from '../../components/onboarding/constants';
import { ROUTES } from '../../shared/constants';
import { useLocation, useNavigate } from 'react-router-dom';

export const OnboardingScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const handleNext = () => {
    if (currentStepIndex < 6) {
      setCurrentStepIndex((prev) => prev + 1);

      return;
    }

    if (location.state?.prevPath) {
      navigate(ROUTES.SETTINGS);

      return;
    }

    if (currentStepIndex > 5) {
      navigate(ROUTES.CHOOSE_FINGER);

      return;
    }
  };

  const handlePrev = () => {
    setCurrentStepIndex((prev) => prev - 1);
  };

  const onSkip = () => {
    if (location.state?.prevPath) {
      navigate(-1);
    } else {
      navigate(ROUTES.CHOOSE_FINGER);
    }
  };

  return (
    <Slider
      key={ONBOARDING_STEPS[currentStepIndex].step}
      step={ONBOARDING_STEPS[currentStepIndex].step}
      img={ONBOARDING_STEPS[currentStepIndex].img}
      title={ONBOARDING_STEPS[currentStepIndex].title}
      description={ONBOARDING_STEPS[currentStepIndex].description}
      onNext={handleNext}
      onPrev={handlePrev}
      screenshots={ONBOARDING_STEPS[currentStepIndex].screenshots}
      onSkip={onSkip}
    />
  );
};
