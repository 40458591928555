import { useCloudStorage } from '@vkruglikov/react-telegram-web-app';
import { useEffect } from 'react';
import { getCurrentTimeUTC } from 'shared/api';
import { CS_KEYS, DAY_IN_MS } from 'shared/constants';
import { useGlobalStore } from 'shared/store';

export const useCheckDailyLimit = () => {
  const { getItem, setItem } = useCloudStorage();

  const setCheckLimitLoading = useGlobalStore((state) => state.setCheckLimitLoading);
  const updateUser = useGlobalStore((store) => store.updateUser);

  const handleCurrentLimit = async () => {
    const lastCurrentDay = await getItem(CS_KEYS.CURRENT_DAY);
    const { currentTimestampUTC } = await getCurrentTimeUTC();

    const currentDay = Math.floor(currentTimestampUTC / DAY_IN_MS);

    if (currentDay !== +lastCurrentDay) {
      await setItem(CS_KEYS.CURRENT_DAY, currentDay.toString());
      updateUser({ current_limit: 0 });
    }
  };

  useEffect(() => {
    const checkLimits = async () => {
      try {
        await handleCurrentLimit();
      } catch (error) {
        console.log(error);
      } finally {
        setCheckLimitLoading(false);
      }
    };

    checkLimits();
  }, []);
};
